import React, { Fragment } from "react";
import "../Login/Login.scss";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import useHttp from "../../custom hook/use-http";
import { useTranslation } from "react-i18next";
import { useReducer } from "react";
import { useRef } from "react";
import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";
import { useState, useEffect } from "react";
import { useChangeLanguage } from "../../custom hook/useChangeLanguage";
import useTimeout from "../../custom hook/useTimeout";
import Toast from "../../sharedComponents/Toast/Toast";

const validateMobileNumber = (mobile, region) => {
  const pattern = region === "UAE" ? /(^(05)\d{8}$)/ : /(^(01)(0|1|2|5)\d{8}$)/;
  return pattern.test(mobile);
};

const mobileReducer = (state, action) => {
  if (action.type === "USER_INPUT") {
    return {
      value: action.val,
      isValid: validateMobileNumber(action.val, action.region),
    };
  } else if (action.type === "USER_BLUR") {
    return {
      value: state.value,
      isValid: validateMobileNumber(state.value, action.region),
    };
  } else if (action.type === "USER_CHANGE_REGION") {
    return { value: action.val, isValid: null };
  }

  return { value: "", isValid: false };
};

const passwordReducer = (state, action) => {
  if (action.type === "USER_INPUT") {
    return { value: action.val, isValid: action.val.trim().length === 4 };
  } else if (action.type === "USER_BLUR") {
    return { value: state.value, isValid: state.value.trim().length === 4 };
  }

  return { value: "", isValid: false };
};

const LoginPassword = () => {
  const mobileInputRef = useRef();
  const passwordInputRef = useRef();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const isUserRegistered = searchParams.get("isExist") ? true : false;
  const [toastVisible, setToastVisible] = useState(isUserRegistered);

  const [region, setRegion] = useState("UAE");

  const hide = () => setToastVisible(false);
  useTimeout(hide, 5000);

  const arabicNumbers = [
    /٠/g,
    /١/g,
    /٢/g,
    /٣/g,
    /٤/g,
    /٥/g,
    /٦/g,
    /٧/g,
    /٨/g,
    /٩/g,
  ];

  const fixNumbers = (str) => {
    if (typeof str === "string") {
      for (var i = 0; i < 10; i++) {
        str = str.replace(arabicNumbers[i], i);
      }
    }
    return str;
  };

  const [mobileState, dispatchMobile] = useReducer(mobileReducer, {
    value: region === "UAE" ? "05" : "01",
    region: region,
    isValid: null,
  });
  const [passwordState, dispatchPassword] = useReducer(passwordReducer, {
    value: "",
    isValid: null,
  });
  const [formIsValid, setFormIsValid] = useState(false);

  const { isValid: passwordIsValid } = passwordState;
  const { isValid: mobileIsValid } = mobileState;

  // useHttp
  const { isLoading, error, sendRequest } = useHttp();
  const { t, i18n } = useTranslation();
  const { changeLanguage } = useChangeLanguage();

  useEffect(() => {
    const keyStrokeTimeout = setTimeout(() => {
      setFormIsValid(mobileIsValid);
      setFormIsValid(passwordIsValid);
    }, 500);

    return () => {
      clearTimeout(keyStrokeTimeout);
    };
  }, [mobileIsValid, passwordIsValid]);

  const passwordChangeHandler = (event) => {
    dispatchPassword({ type: "USER_INPUT", val: event.target.value });
  };

  const validatePasswordHandler = () => {
    dispatchPassword({ type: "USER_BLUR" });
  };

  const onRegionChange = (region) => {
    region === "UAE"
      ? dispatchMobile({ type: "USER_CHANGE_REGION", val: "05" })
      : dispatchMobile({ type: "USER_CHANGE_REGION", val: "01" });

    localStorage.setItem("region", region);
    setRegion(region);
  };

  const mobileKeyDownHandler = (event) => {
    if (
      (event.key === "Backspace" && mobileState.value.trim().length < 3) ||
      ((event.keyCode < 48 || event.keyCode > 57) && event.keyCode !== 8)
    ) {
      event.preventDefault();
    }
  };

  const mobileChangeHandler = (event) => {
    dispatchMobile({
      type: "USER_INPUT",
      val: fixNumbers(event.target.value),
      region: region,
    });
  };

  const validateMobileHandler = () => {
    dispatchMobile({ type: "USER_BLUR", region: region });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    loginRequest();
  };

  const loginRequest = () => {
    let usernumber;
    if (region === "UAE") {
      localStorage.setItem("region", region);
      usernumber = 971 + mobileState.value.slice(1, 10);
      localStorage.setItem("UAEMsisdn", usernumber);
    } else {
      usernumber = 2 + mobileState.value;
      localStorage.setItem("region", region);
    }
    const options = {
      method: "POST",
      url: "https://auth.best-fortuneteller.com/api/account/login",
      data: {
        Username: usernumber,
        Password: passwordState.value,
      },
    };
    sendRequest(options, setToken);
  };

  const setToken = (res) => {
    if (res.status === 200) {
      localStorage.setItem("token", res.data.token);
      navigate("/", { replace: true });
    }
  };

  return (
    <Fragment>
      {toastVisible && (
        <Toast message="you have already subscribed, Username and password were sent via SMS." />
      )}
      <div className="mx-auto login pt-2">
        <div className="d-flex justify-content-end">
          <button
            className="login__change__language"
            onClick={() => changeLanguage(i18n.language === "ar" ? "en" : "ar")}
          >
            {i18n.language === "ar" ? "en" : "ar"}
          </button>
        </div>
        <div className="mx-auto text-center mt-4 mb-3">
          <img
            src={require("../../assets/img/logo/logo.png")}
            alt=""
            width="100px"
          />
        </div>
        <h1 className="text-center mb-5">{t("BestFortune")}</h1>

        <h3 className="text-white font-weight-light text-center h4">
          {t("StepAwayFortune")}
        </h3>
        <form onSubmit={submitHandler}>
          <label
            className={i18n.language === "ar" ? `flex-row-reverse` : `flex-row`}
          >
            {t("selectRegion")}
            <select
              value={region}
              className={i18n.language === "ar" ? `ml-0` : ``}
              onChange={(e) => onRegionChange(e.target.value)}
            >
              <option value="UAE">UAE</option>
              <option value="Egypt">Egypt</option>
            </select>
          </label>
          {/* <div style={{color: 'white'}}>{region === "UAE" ? "UAE" : "Egypt"}</div> */}
          <Input
            ref={mobileInputRef}
            type="tel"
            id="mobile"
            onChange={mobileChangeHandler}
            onBlur={validateMobileHandler}
            onKeyDown={mobileKeyDownHandler}
            placeholder={region === "UAE" ? `05--------` : `01---------`}
            pattern={region === "UAE" ? `05[0-9۰-۹٠-٩]{8}` : `01[0-9۰-۹٠-٩]{9}`}
            state={mobileState}
            value={mobileState.value || (region === "UAE" ? "05" : "01")}
            maxLength={region === "UAE" ? 10 : 11}
            tabIndex={1}
          />

          <Input
            ref={passwordInputRef}
            type="password"
            id="password"
            onChange={passwordChangeHandler}
            onBlur={validatePasswordHandler}
            placeholder="****"
            pattern="[0-9]{4}"
            value={passwordState.value || ""}
            state={passwordState}
            maxLength="4"
            tabIndex={2}
          />
          <Button type="submit" className="w-50 mt-2" disabled={!formIsValid}>
            {t("Login")}
          </Button>

          {/* <div
            className={`text-center mt-2 mx-auto d-flex justify-content-center ${
              i18n.language === "en" ? "ltr" : "rtl"
            }`}
          >
            <div className="text-white">{t("notSubscribed")}</div>
            <Link to={"/login"} className="ml-1">
              {t("Subscribe")}
            </Link>
          </div> */}
        </form>

        <div className="error__message"> {error && error.response.data}</div>
      </div>
    </Fragment>
  );
};

export default LoginPassword;
