import React from "react";
import { useImperativeHandle } from "react";
import { useRef } from "react";

import classes from "./Input.module.scss";

const Input = React.forwardRef((props, ref) => {
  const inputRef = useRef();

  const activate = () => {
    inputRef.current.focus();
  };

  useImperativeHandle(ref, () => {
    return {
      focus: activate,
    };
  });

  return (
    <div
      className={`${classes.control} 
        ${props.state.isValid === false ? classes.invalid : ""}`}
    >
      <input
        type={props.type}
        id={props.id}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onKeyDown={props.onKeyDown}
        // onKeyPress={props.onKeyDown}
        autoComplete="off"
        onBlur={props.onBlur}
        pattern={props.pattern || ''}
        maxLength={props.maxLength || ''}
        ref={inputRef}
        tabIndex={props.tabIndex}
      />
    </div>
  );
});

export default Input;
