import React, { useCallback, useState } from "react";
import axios from "axios";
import { useEffect } from "react";
axios.defaults.baseURL = "https://api.best-fortuneteller.com/api";

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.defaults.headers.common["Token"] = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : null;
  }, []);

  const sendRequest = useCallback((requestConfig, applyData) => {
    setIsLoading(true);
    setError(null);
    axios(requestConfig)
      .then((res) => {
        res && applyData(res);
        setIsLoading(false);
      })
      .catch((err) => {

        setError(err);
        setIsLoading(false);
      });


  }, []);

  return {
    isLoading,
    error,
    sendRequest,
  };
};

export default useHttp;
