import { useTranslation } from "react-i18next";

export const useChangeLanguage = () => {
  const { i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  };

  return {
    changeLanguage
  }
};
