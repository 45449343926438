let ar = {
  translations: {
    "Birthday omen": "فأل عيد ميلاد",
    Horoscope: "ابراج",
    Palm: "كف",
    Coffee: "قهوه",
    Tarot: "التارو",
    "Animal Spirit": "روح الحیوانیه",
    WhichAnimal: "انت شبه ای حیوان",
    errorOccured: "حدث خطأ. حاول مرة اخرى",
    myFortune: "قل لی عن طالعی",
    Animal: "حیوان",
    Loading: "جار التحميل...",
    Uploading: "جارٍ التحميل ...",
    CoffeeCupGuide: "إرشادات قراءة فنجان القهوة",
    CoffeeDesc: `اقلب الكوب رأسًا على عقب على الصحن.
    فكر فينية وابدأ بالتقاط صورة داخل فنجان القهوة الخاص بك ،`,
    isNotCup: "الصورة ليست كأس!",
    Start: "ابدأ",
    Upload: "حمل",
    CancelCamera: "إلغاء الكاميرا",
    CameraPermission: "تم رفض إذن الكاميرا",
    AllowPermission: "يرجى السماح بالإذن أو تحميل صورتك",
    ChooseBirthday: "اختر عيد الميلاد الذي تريده:",
    BestFortune: "أفضل عراف",
    StepAwayFortune: "فاضل خطوة واحدة بس عشان تقدر تشاهد الطالع بتاعك",
    ContinueGoogle: "متابعه مع جوجل",
    FindSecrets: "اعثر على الأسرار الموجوده في يدك",
    isNotPalm: "الصورة ليست  صوره من کف الید!",
    ScanHand: `من خلال تقنية المسح والقراءة المتقدمة ،
    يمكن لـ Palm Reader القيام بذلك
     توقع طالعك وحبك وحياتك والعديد من الجوانب الأخرى.`,
    TakePicture: "التقط صوره",
    DeniedCamera:
      "تم رفض إذن الكاميرا أو لا يمكنك استخدام الكاميرا على هذا الجهاز",
    PalmReading: "قراته الکف",
    Birthday: "عید میلاد",
    YourIntention: "حدد نيتك ، ركز عليها ولنبدأ ",
    AboutUs: `لدى Portutel فريق من الخبراء من مطوري تطبيقات وألعاب الأجهزة المحمولة الذين يمكنهم تحويل الأفكار إلى واقع ، وتقديم حلول متميزة في شكل تطبيقات.
    نحن نقدم نماذج مشاركة مرنة ونقدم حلولًا خاصة بالمجال تتوافق مع اتجاهات الصناعة مع تلبية المتطلبات ، مما يضمن أن خدماتنا في الفئات المختلفة تلبي احتياجات المستخدمين`,
    AboutUsExtra: `تم إنشاء Portutel بهدف تطوير ونشر وترويج تطبيقات وألعاب الجوال المثيرة في فئات مختلفة من خلال النظر في الخصائص المحددة لكل سوق.
    يقع مكتبنا المركزي في البرتغال مع التركيز على السوق المحلية والعالمية.
    لقد دخلنا في شراكة مع السمعة الطيبة مع شركات التسويق ومشغلي الهاتف المحمول لتقديم تطبيقات الهاتف المحمول ذات المستوى العالمي والحصول على حضور أقوى في الأسواق الناشئة.`,
    AboutUsTitle: "معلومات عنا",
    More: "اکثر",
    Less: "اقل",
    PrivacyTitle: "الخصوصية",
    Privacy: `خصوصيتك مهمة جدا بالنسبة لنا.
    فيما يلي وصف لسياسة الخصوصية الخاصة بنا.
    قد نطلب منك تزويدنا ببعض معلومات التعريف الشخصية ، بما في ذلك تاريخ الميلاد و قد لایکون مجرد هذه المعلومات. 
   نقوم بجمع المعلومات الشخصية وفقًا للوسائل القانونية والعادلة ، وعند الاقتضاء ، بمعرفة أو موافقة الفرد المعني.`,
    PrivacyExtra: `سنحمي المعلومات الشخصية من خلال ضمانات أمنية معقولة ضد الضياع أو السرقة . 
    فضلاً عن الوصول غير المصرح به أو الكشف أو النسخ أو الاستخدام أو التعديل.`,
    TermsTitle: "البنود و الظروف",
    TermsCondition: `هل تحب أن تعرف طالعك؟ فن العرافة مهم لحياتنا اليومية سواء أدركنا ذلك أم لا. 
    مع Best Fortune Teller ، أنت بالضبط حيث يجب أن تكون! في هذا التطبيق . 
    يمكنك معرفة ما يوفره لك الكون لليوم أو الأسبوع أو الشهر`,
    TermsConditionExtra: `لا أحد منا يمكنه بالفعل تغيير الماضي أو المستقبل ، لكن يمكننا استخدام المعلومات هنا لمعرفة إلى أين نتجه! في هذا التطبيق ، يمكنك الحصول على برجك اليومي ، وحيوان الروح ، وفأل عيد ميلاد ، والتارو ، وقراءة فنجان القهوة ، وقراءة راحة يدك.
    يرجى ملاحظة أن الغرض من الخدمة هو التسلية فقط.
    من أجل استخدام الخدمة يجب على المستخدمين الاشتراك. بعد
     بالاشتراك في Best Fortune Teller ، يمكن للمستخدم استخدام جميع الميزات
    طوال مدة الاشتراك. 
    المقدم الخدمة ليس له تأثير على المستخدمين والمشغلين الذين لا يستطيعون ذلك
    تأكد من أن المعلومات التي يتم تبادلها من قبل المستخدمين والمشغلين هي صحیحه
    يحاول مزود الخدمة توفير الحد الأقصى إمكانية توفر الخدمة. ومع ذلك ، بسبب
     الخصائص التقنية للاتصال بالإنترنت والجوال
     المهاتفة ، التي ليس لمزود الخدمة تأثير عليها ، قد ينقطع إرسال البيانات في بعض الأحيان. لاستخدام الخدمة ، يجب على المستخدم استخدام هاتف محمول يعمل وله اتصال (إشارة) صالح على هاتفه المحمول. بدون هذه الشروط لا يمكن استخدام الخدمة`,
    January: "كانون الثاني",
    February: "فبراير",
    March: "مارس",
    April: "أبريل",
    May: "مايو",
    June: "يونيو",
    July: "تموز",
    August: "أغسطس",
    September: "سبتمبر",
    October: "اكتوبر",
    November: "نوفمبر",
    December: "ديسمبر",
    Logout: "تسجیل خروج",
    Login: "تسجيل الدخول",
    Unsubscribe: "إلغاء الاشتراك",
    Subscribe: "الاشتراك",
    Confirm: "تؤكد",
    notSubscribed: "ليس لديك اشتراك ؟",
    resendOtp: "اعادة ارسال رمز التحقق",
    selectRegion: 'اختر منطقتك'
  },
};

export default ar;
