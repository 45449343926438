import React from "react";
import "../Login/Login.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import useHttp from "../../custom hook/use-http";

import { useTranslation } from "react-i18next";
import { useReducer } from "react";
import { useRef } from "react";

import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";

import { useState, useEffect } from "react";
import { useChangeLanguage } from "../../custom hook/useChangeLanguage";
import useInterval from "../../custom hook/useInterval";

const passwordReducer = (state, action) => {
  if (action.type === "USER_INPUT") {
    return { value: action.val, isValid: action.val.trim().length === 6 };
  } else if (action.type === "USER_BLUR") {
    return { value: state.value, isValid: state.value.trim().length === 6 };
  }

  return { value: "", isValid: false };
};

const LoginOtp = () => {
  const [visible, setVisible] = useState(true);
  // The counter
  const [count, setCount] = useState(60);
  // Dynamic delay
  const [delay, setDelay] = useState(1000);

  useInterval(
    () => {
      // Your custom logic here
      setCount(count - 1);
      if (count === 0) {
        setVisible(false);
        setCount(0);
      }
    },
    // Delay in milliseconds or null to stop it
    visible ? delay : null
  );

  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const passwordInputRef = useRef();
  const msisdn = searchParams.get("msisdn");

  const [passwordState, dispatchPassword] = useReducer(passwordReducer, {
    value: "",
    isValid: null,
  });

  const [formIsValid, setFormIsValid] = useState(false);

  // const { isValid: mobileIsValid } = mobileState;
  const { isValid: passwordIsValid } = passwordState;

  // useHttp
  const { isLoading, error, sendRequest } = useHttp();
  const { t, i18n } = useTranslation();
  const { changeLanguage } = useChangeLanguage();

  useEffect(() => {
    const keyStrokeTimeout = setTimeout(() => {
      setFormIsValid(passwordIsValid);
    }, 500);

    return () => {
      clearTimeout(keyStrokeTimeout);
    };
  }, [passwordIsValid]);

  const passwordChangeHandler = (event) => {
    dispatchPassword({ type: "USER_INPUT", val: event.target.value });
  };

  const validatePasswordHandler = () => {
    dispatchPassword({ type: "USER_BLUR" });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    loginRequest();
  };

  const onResendOTP = () => {
    const options = {
      method: "POST",
      url: "https://auth.best-fortuneteller.com/api/account/otp/resend",
      data: {
        Msisdn: msisdn,
      },
    };
    sendRequest(options, (res) => {
      if (res.status === 200) {
        setVisible(true);
        setCount(60);
      }
    });
  };

  const loginRequest = () => {
    const options = {
      method: "POST",
      url: "https://auth.best-fortuneteller.com/api/account/otp/login",
      data: {
        Msisdn: msisdn,
        OtpCode: passwordState.value,
      },
    };
    sendRequest(options, setToken);
  };

  const setToken = (res) => {
    if (res.status === 200) {
      localStorage.setItem("token", res.data.token);
      navigate("/", { replace: true });
    }
  };

  return (
    <div className="mx-auto login pt-2">
      <div className="d-flex justify-content-end">
        <button
          className="login__change__language"
          onClick={() => changeLanguage(i18n.language === "ar" ? "en" : "ar")}
        >
          {i18n.language === "ar" ? "en" : "ar"}
        </button>
      </div>
      <div className="mx-auto text-center mt-4 mb-3">
        <img
          src={require("../../assets/img/logo/logo.png")}
          alt=""
          width="100px"
        />
      </div>
      <h1 className="text-center mb-5">{t("BestFortune")}</h1>

      <h3 className="text-white font-weight-light text-center h4">
        {t("StepAwayFortune")}
      </h3>
      <form onSubmit={submitHandler}>
        <div className="text-center text-white h3 font-weight-bold font-bold mt-5">
          {msisdn}
        </div>

        <Input
          ref={passwordInputRef}
          type="password"
          id="password"
          onChange={passwordChangeHandler}
          onBlur={validatePasswordHandler}
          placeholder="******"
          value={passwordState.value || ""}
          pattern="[0-9]{6}"
          state={passwordState}
          maxLength="6"
          tabIndex={2}
        />

        <Button type="submit" className="w-50 mt-2" disabled={!formIsValid}>
          {t("Confirm")}
        </Button>
        <div
          className={`text-center mt-2 mx-auto d-flex justify-content-center`}
        >
          {!visible ? (
            <div className="ml-1 text-white" onClick={onResendOTP}>
              {t("resendOtp")}
            </div>
          ) : (
            <div className="text-center text-white">
              {count === 60 ? "1:00" : "0:" + count}
            </div>
          )}
        </div>
      </form>

      <div className="error__message"> {error && "Invalid OTP"}</div>

      {i18n.language === "en" ? (
        <div className="regulation text-white ltr mt-5 px-2 mx-auto">
          You will subscribe to Best Fortune Teller for{" "}
          <span className="text-danger">5 Egyptian Pounds daily</span>{" "}
          auto-renewed & consumed from the internet bundle. To cancel your
          subscription, please send UNSUB BFT to 6699 for Vodafone subscribers,
          to 5030 for Orange, to 1722 for Etisalat and 4041 to We for free. To
          cancel from the site please go to
          https://best-fortuneteller.com/gen/unsub/request/en/bestfortuneteller
          and click on (unsubscribe). For any inquires please contact us by
          email at bestfortuneteller@portutel.com. Internet usage is deducted
          from your internet bundle. Terms and conditions apply.
        </div>
      ) : (
        <div className="regulation text-white rtl mt-5 px-2 mx-auto text-right">
          سوف تقوم بالاشتراك فى خدمة [بست فورتون تلر] مقابل
          <span className="text-danger"> 5 جنیه مصری یومیا</span> تجدد تلقائيا
          وتخصم من الباقة الخاصة بك.لالغاء الاشتراك مجانا, لعملاء Vodafone ارسل
          UNSUB BFT الى 6699 و 1722 Etisalat و 5030 orange و 4041 WE .للالغاء من
          الموقع بالرجاء الدخول ال
          https://bestfortuneteller.com/gen/unsub/request/en/bestfortuneteller و
          الضغط علي الغاء الاشتراك. لأية استفسارات يرجى التواصل معانا على
          bestfortuneteller@portutel.com .استهلاك الانترنت سوف يخصم من الباقة
          الخاصة بك. تطبق الشروط و الاحكام.
        </div>
      )}
    </div>
  );
};

export default LoginOtp;
