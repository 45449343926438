import style from './Toast.module.scss'

const Toast = (props) => {
  return (
    <section
      className={`toast ${style['toast-modal']}`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className="toast-header">
        <strong className="mr-auto">Best fortune teller</strong>

        <button
          type="button"
          className="ml-2 mb-1 close"
          data-dismiss="toast"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="toast-body">{props.message}</div>
    </section>
  );
};

export default Toast;
