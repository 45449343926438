import React, { Suspense, useContext } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import AuthContext from "./contexts/AuthContext";
import LoginOtp from "./components/LoginOtp/LoginOtp";
import Login from "./components/Login/Login";
import LoginPassword from "./components/LoginPassword/LoginPassword";
const AnimalSpirit = React.lazy(() =>
  import("./components/Animalspirit/Animalspirit")
);

const Horoscope = React.lazy(() => import("./components/Horoscope/Horoscope"));
const Home = React.lazy(() => import("./components/Home/Home"));
const Tarot = React.lazy(() => import("./components/Tarot/Tarot"));
const Coffee = React.lazy(() => import("./components/Coffee/Coffee"));
const Palmreader = React.lazy(() =>
  import("./components/Palmreader/Palmreader")
);

const Aboutus = React.lazy(() => import("./components/AboutUs/AboutUs"));
const Privacy = React.lazy(() => import("./components/Privacy/Privacy"));

const Videos = React.lazy(() => import("./components/Videos/Videos"));
const VideoList = React.lazy(() => import("./components/Video-List/VideoList"));
const TermsAndConditions = React.lazy(() =>
  import("./components/Terms&Conditions/TermsAndConditions")
);

const VideoPlay = React.lazy(() => import("./components/VideoPlay/VideoPlay"));

const RequireAuth = ({ children }) => {
  const authContext = useAuth();
  const location = useLocation();

  authContext.isAuthenticated = checkToken();

  if (!authContext.isAuthenticated) {
    return <Navigate to="/login-password" state={{ from: location }} replace />;
  }

  return children;
};

const useAuth = () => {
  return useContext(AuthContext);
};

const checkToken = () => {
  let token = localStorage.getItem("token");
  //console.log(token);
  if (!token) {
    return false;
  } else {
    let decoded = jwt_decode(token);
    if (new Date(decoded.exp * 1000) > new Date()) {
      return true;
    } else {
      localStorage.removeItem("token");
      window.location.reload();
      return false;
    }
  }
};

const AuthWrapper = (props) => {
  return (
    <Suspense fallback={<div className="text-white">Loading...</div>}>
      <RequireAuth>{props.children}</RequireAuth>
    </Suspense>
  );
};

const PageRoutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        index
        element={
          <AuthWrapper>
            <Home />
          </AuthWrapper>
        }
      />
      <Route path="/login-password" element={<LoginPassword />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login-otp" element={<LoginOtp />} />
      <Route
        path="/Horoscope"
        element={
          <AuthWrapper>
            <Horoscope />
          </AuthWrapper>
        }
      />

      <Route
        path="/Birthday"
        element={
          <AuthWrapper>
            <Horoscope />
          </AuthWrapper>
        }
      />

      <Route
        path="/AnimalSpirit"
        element={
          <AuthWrapper>
            <AnimalSpirit />
          </AuthWrapper>
        }
      />

      <Route
        path="/Tarot"
        element={
          <AuthWrapper>
            <Tarot />
          </AuthWrapper>
        }
      />
      <Route
        path="/Coffee"
        element={
          <AuthWrapper>
            <Coffee />
          </AuthWrapper>
        }
      />
      <Route
        path="/Palmreader"
        element={
          <AuthWrapper>
            <Palmreader />
          </AuthWrapper>
        }
      />

      <Route
        path="/videos"
        element={
          <AuthWrapper>
            <Videos />
          </AuthWrapper>
        }
      />

      <Route
        path="/video-list/:id"
        element={
          <AuthWrapper>
            <VideoList />
          </AuthWrapper>
        }
      />

      <Route
        path="/video-play"
        element={
          <AuthWrapper>
            <VideoPlay />
          </AuthWrapper>
        }
      />

      <Route
        path="/about-us"
        element={
          <AuthWrapper>
            <Aboutus />
          </AuthWrapper>
        }
      />
      <Route
        path="/privacy"
        element={
          <AuthWrapper>
            <Privacy />
          </AuthWrapper>
        }
      />
      <Route
        path="/termsAndConditions"
        element={
          <AuthWrapper>
            <TermsAndConditions />
          </AuthWrapper>
        }
      />
    </Routes>
  );
};

export default PageRoutes;
